import React, { forwardRef } from "react";
import Link from "next/link";
import { InternalLinkProps, ProjectProps } from '@/types/index';
import styles from "@/styles/card.module.css";
// @ts-ignore
import placeholder from "@/public/placeholder.png"


const CardLink = forwardRef<HTMLAnchorElement, InternalLinkProps>((props, ref) => {
  const { href, as, children, ...rest } = props;
  return (
    <Link href={href} passHref>
      <a ref={ref} {...rest} className={styles.link}>
        {children}
      </a>
    </Link>
  );
});

export const ProjectCard = ({ title, subheader, description, mainImage, slug, tags }: ProjectProps) => {
  const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);
  return (
    <>
      <div className="w-100 rounded overflow-hidden shadow-lg h-100 py-3 transform duration-500 ease-in-out hover:scale-105">
        <CardLink href={`/projects/${slug}`}>
          <img className="w-full h-auto" src={mainImage || placeholder} alt={subheader}></img>
          <div className="px-6 py-4">
            <h1 className="font-bold text-xl mb-2 text-center">
              {capitalizeFirstLetter(title)}
            </h1>
            <p className="text-gray-700 text-base text-center">
              {description}
            </p>
          </div>
          {/* <div className="px-6 pt-4 pb-2">
            {tags && tags.map((tag, id) => (
              <span className="inline bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" key={id}>{tag}</span>
            ))}
          </div> */}
        </CardLink>
      </div>
    </>
  );
};
