import React from "react";
import { MenuToggleProps } from "@/types/index";
// import styles from "@/styles/hamburger.module.scss";

export const HamburgerMenu = ({ clickHandler, isOpen }: MenuToggleProps) => {
  const genericHamburgerLine = `h-1 w-6 my-1 cursor-pointer leading-none bg-gray-500 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none transition ease transform duration-300`;

  const toggleMenu = () => {
    clickHandler();
  }

  return (
    <button
      className={`my-3 flex flex-col h-12 w-12 rounded group lg:invisible outline-none nav-toggler`}
      onClick={toggleMenu}
    >
      <div
        className={`${genericHamburgerLine}  ${isOpen ? "rotate-45 translate-y-3 opacity-50 group-hover:opacity-100" : "opacity-50 group-hover:opacity-100"}`}
      />
      <div
        className={`${genericHamburgerLine}  ${isOpen ? "opacity-0" : "opacity-50 group-hover:opacity-100"}`}
      />
      <div
        className={`${genericHamburgerLine}  ${isOpen ? "-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100" : "opacity-50 group-hover:opacity-100"}`}
      />
    </button>
  );
}


       {/* <button
        className="text-white inline-flex p-3 hover:bg-gray-900 rounded lg:hidden ml-auto hover:text-white outline-none nav-toggler"
        data-target="#navigation"
        onClick={handleToggle}
      >
        <i className="material-icons">menu</i> */}