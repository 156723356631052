import React, { useState } from "react";
import Link from "next/link";
import { LogoLockup as Lockup } from "./svg/Logo-Lockup";
import { HamburgerMenu } from "./HamburgerMenu";
import { Navlink } from "./Navlink";
import styles from "@/styles/navbar.module.scss";

export const Navbar = () => {
  const [isOpen, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 bg-gray-200 mb-3">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between items-center lg:w-auto lg:justify-start">
            <a href="/" className="py-2">
              <Lockup width={172} height={172} />
            </a>
            <HamburgerMenu isOpen={isOpen} clickHandler={handleToggle} />
          </div>
          <div
            className={
              "lg:flex flex-grow items-center justify-center" +
              (isOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto pb-4">
              <li className="nav-item">
                <a className="antialiased px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-gray-500 hover:text-white" href="/" >
                  <span className="mx-auto">Home</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="antialiased px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-gray-500 hover:text-white" href="/projects">
                  <span className="mx-auto">Projects</span>
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-gray-500 hover:text-white" href="/blog" >
                  <span className="mx-auto">Blog</span>
                </a>
              </li> */}
              <li className="nav-item">
                <a className="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-gray-500 hover:text-white" href="/contact" >
                  <span className="mx-auto">Contact</span>
                </a>
              </li>
              
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};