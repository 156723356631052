import React from "react";
import { HomeIconProps } from "@/types/index";


export const LogoLockup = ({
  width,
  height,
  color,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 188 60" fill={color}>
      <path
        d="M8.79605 23.605C5.82105 23.605 3.75605 21.96 2.60105 20.875L0.0810547 24.48C1.13105 25.53 3.93105 27.35 8.27106 27.35C14.1161 27.35 17.1261 23.955 17.1261 20.28C17.1261 12.545 5.71605 13.385 5.71605 8.765C5.71605 7.155 6.94106 5.93 9.14606 5.93C11.5261 5.93 12.9611 7.155 13.8011 7.995L16.4961 4.705C15.4811 3.62 13.0661 2.115 9.49606 2.115C4.66606 2.115 1.23605 5.16 1.23605 9.115C1.23605 16.85 12.6811 16.045 12.6811 20.56C12.6811 22.24 11.3861 23.605 8.79605 23.605Z"
        fill="#231F20"
      />
      <path
        d="M27.1577 27.175C30.0627 27.175 31.4977 26.545 32.1977 26.09L31.0427 22.8C30.3777 23.29 29.3977 23.605 28.2777 23.605C26.8427 23.605 25.9327 22.835 25.9327 21.155V10.83H31.0077V7.505H25.9327V2.5H23.1327L21.6977 7.26L19.0377 8.135V10.83H21.6977V21.155C21.6977 25.495 23.8677 27.175 27.1577 27.175Z"
        fill="#231F20"
      />
      <path
        d="M47.8271 22.205C47.0571 22.94 45.5171 23.85 43.4521 23.85C39.9171 23.85 38.3071 22.1 38.1671 18.39H50.1021C50.2771 16.99 50.3121 15.94 50.3121 14.96C50.3121 10.235 47.5821 7.155 42.7521 7.155C37.6071 7.155 33.9321 10.585 33.9321 17.2C33.9321 24.095 37.0121 27.35 42.9621 27.35C46.3221 27.35 48.2821 26.44 49.7171 25.32L47.8271 22.205ZM42.5771 10.515C45.0621 10.515 46.2171 11.95 46.2171 14.61V15.38H38.2371C38.3771 12.195 40.1621 10.515 42.5771 10.515Z"
        fill="#231F20"
      />
      <path
        d="M70.842 16.43C70.842 10.27 67.937 7.155 63.597 7.155C61.147 7.155 59.607 8.275 58.802 9.255H58.662L57.752 7.505H54.567V33.79H58.837V26.755C59.782 26.965 60.797 27.07 61.882 27.07C66.887 27.07 70.842 23.5 70.842 16.43ZM58.802 22.975V12.65C59.432 11.6 60.622 10.69 62.267 10.69C64.507 10.69 66.432 12.37 66.432 16.85C66.432 21.645 64.647 23.5 61.427 23.5C60.797 23.5 59.572 23.36 58.802 22.975Z"
        fill="#231F20"
      />
      <path
        d="M84.7373 7.295C82.1123 7.295 80.3273 8.555 79.4173 9.535H79.2773V0.470001H74.9723V27H79.2773V13.63C79.9423 12.23 81.5173 11.005 83.3023 11.005C85.4023 11.005 86.4523 12.335 86.4523 14.54V27H90.7923V13.735C90.7923 9.5 88.5173 7.295 84.7373 7.295Z"
        fill="#231F20"
      />
      <path
        d="M108.701 22.205C107.931 22.94 106.391 23.85 104.326 23.85C100.791 23.85 99.1811 22.1 99.0411 18.39H110.976C111.151 16.99 111.186 15.94 111.186 14.96C111.186 10.235 108.456 7.155 103.626 7.155C98.4811 7.155 94.8061 10.585 94.8061 17.2C94.8061 24.095 97.8861 27.35 103.836 27.35C107.196 27.35 109.156 26.44 110.591 25.32L108.701 22.205ZM103.451 10.515C105.936 10.515 107.091 11.95 107.091 14.61V15.38H99.1111C99.2511 12.195 101.036 10.515 103.451 10.515Z"
        fill="#231F20"
      />
      <path
        d="M131.261 13.525C131.226 9.15 128.986 7.05 125.136 7.05C122.721 7.05 120.726 8.275 119.781 9.43H119.641L118.766 7.505H115.441V27H119.746V13.49C120.376 11.915 121.846 10.76 123.701 10.76C125.801 10.76 126.921 12.09 126.921 14.47V27H131.261V13.525Z"
        fill="#231F20"
      />
      <path
        d="M153.027 11.74C153.657 9.57 154.392 6.945 154.707 5.475H154.847C155.127 7.015 155.862 9.465 156.527 11.845L158.067 17.095H151.417L153.027 11.74ZM151.662 2.465L144.067 27H148.477L150.332 20.77H159.152L161.007 27H165.592L157.962 2.465H151.662Z"
        fill="#231F20"
      />
      <path
        d="M168.485 20.665C168.52 25.215 170.62 27.385 174.75 27.385C177.48 27.385 179.125 26.265 180.035 25.11H180.175L180.84 27H184.41V7.505H180.105V20.735C179.895 22.275 178.6 23.955 176.15 23.955C173.98 23.955 172.825 22.695 172.825 20.14V7.505H168.485V20.665Z"
        fill="#231F20"
      />
      <path
        d="M5.56614 57.76C3.88614 57.76 2.76614 57.08 1.98614 56.44L1.04614 57.72C1.84614 58.44 3.30614 59.2 5.42614 59.2C8.42614 59.2 10.1061 57.44 10.1061 55.32C10.1061 51 3.42614 51.68 3.42614 48.44C3.42614 47.16 4.40614 46.24 6.04614 46.24C7.22614 46.24 8.08614 46.64 8.78614 47.2L9.76614 45.96C8.96614 45.3 7.72614 44.78 6.14614 44.78C3.62614 44.78 1.80614 46.42 1.80614 48.54C1.80614 52.96 8.48614 52.24 8.48614 55.42C8.48614 56.72 7.46614 57.76 5.56614 57.76Z"
        fill="#0C7BC1"
      />
      <path
        d="M13.7502 51.92C13.7502 47.56 15.2302 46.22 17.5102 46.22C19.8502 46.22 21.3502 47.68 21.3502 52.06C21.3502 56.4 19.8902 57.76 17.5702 57.76C15.2502 57.76 13.7502 56.3 13.7502 51.92ZM22.9902 51.92C22.9902 46.76 20.9502 44.78 17.6302 44.78C14.2502 44.78 12.1102 46.88 12.1102 52.06C12.1102 57.22 14.1302 59.2 17.4702 59.2C20.8302 59.2 22.9902 57.1 22.9902 51.92Z"
        fill="#0C7BC1"
      />
      <path
        d="M27.5105 59V52.98H32.5905V51.56H27.5105V46.4H33.2905V44.98H25.8905V59H27.5105Z"
        fill="#0C7BC1"
      />
      <path
        d="M37.6538 59H39.2538V46.4H42.9738V44.98H33.9138V46.4H37.6538V59Z"
        fill="#0C7BC1"
      />
      <path
        d="M47.9851 57.48C47.965 57.16 47.1651 53.98 47.0851 53.66L44.8451 44.98H43.1851L46.805 59H49.105L51.3851 49.92C51.465 49.66 52.1651 46.74 52.2051 46.42H52.285C52.3251 46.72 53.0651 49.64 53.1651 50.04L55.4051 59H57.7251L61.3251 44.98H59.7451L57.4851 53.84C57.405 54.2 56.6451 57.2 56.6251 57.46H56.5451C56.5251 57.2 55.7851 54.32 55.7051 54L53.4251 44.98H51.125L48.9251 53.9C48.8651 54.18 48.1051 57.14 48.0651 57.48H47.9851Z"
        fill="#0C7BC1"
      />
      <path
        d="M65.9515 48.74C66.0515 48.36 66.7114 46.32 66.7514 46.08H66.8315C66.8914 46.34 67.5515 48.38 67.6515 48.78L69.1515 53.5H64.4315L65.9515 48.74ZM65.5714 44.98L61.0315 59H62.6715L63.9715 54.92H69.6115L70.9115 59H72.5915L68.0515 44.98H65.5714Z"
        fill="#0C7BC1"
      />
      <path
        d="M74.4265 44.98V59H76.0265V53.18H77.1265C78.1465 53.18 78.7665 53.6 79.3065 54.52L81.8465 59H83.7465L81.1665 54.62C80.5265 53.54 80.0265 53.08 79.4665 52.82V52.74C81.1465 52.56 82.6265 51.22 82.6265 48.92C82.6265 46.12 80.8665 44.98 77.9465 44.98H74.4265ZM76.0265 46.4H77.7265C79.8465 46.4 80.9865 47.14 80.9865 49.1C80.9865 51.04 79.7665 51.8 77.8265 51.8H76.0265V46.4Z"
        fill="#0C7BC1"
      />
      <path
        d="M93.6847 59V57.58H87.5647V52.6H92.7047V51.18H87.5647V46.4H93.4047V44.98H85.9647V59H93.6847Z"
        fill="#0C7BC1"
      />
      <path
        d="M109.593 59V57.58H103.473V52.6H108.613V51.18H103.473V46.4H109.313V44.98H101.873V59H109.593Z"
        fill="#0C7BC1"
      />
      <path
        d="M113.86 46.88C113.98 47.22 115.32 49.78 115.46 50.06L120.22 59H122.42V44.98H120.92V53.26C120.92 53.58 120.92 56.6 120.96 57.02H120.82C120.7 56.72 119.34 54.14 119.2 53.86L114.48 44.98H112.24V59H113.76V50.6C113.76 50.1 113.74 47.18 113.7 46.88H113.86Z"
        fill="#0C7BC1"
      />
      <path
        d="M131.365 44.78C128.025 44.78 125.345 46.98 125.345 52.04C125.345 56.82 127.265 59.2 130.885 59.2C133.045 59.2 134.385 58.68 135.285 57.94V51.88H133.685V57.24C133.125 57.62 132.125 57.8 131.225 57.8C128.485 57.8 126.985 56.12 126.985 51.9C126.985 47.32 129.225 46.24 131.265 46.24C132.665 46.24 133.585 46.62 134.185 47.2L135.225 45.98C134.765 45.54 133.685 44.78 131.365 44.78Z"
        fill="#0C7BC1"
      />
      <path d="M138.719 59H140.319V44.98H138.719V59Z" fill="#0C7BC1" />
      <path
        d="M145.721 46.88C145.841 47.22 147.181 49.78 147.321 50.06L152.081 59H154.281V44.98H152.781V53.26C152.781 53.58 152.781 56.6 152.821 57.02H152.681C152.561 56.72 151.201 54.14 151.061 53.86L146.341 44.98H144.101V59H145.621V50.6C145.621 50.1 145.601 47.18 145.561 46.88H145.721Z"
        fill="#0C7BC1"
      />
      <path
        d="M165.586 59V57.58H159.465V52.6H164.605V51.18H159.465V46.4H165.306V44.98H157.866V59H165.586Z"
        fill="#0C7BC1"
      />
      <path
        d="M175.932 59V57.58H169.812V52.6H174.952V51.18H169.812V46.4H175.652V44.98H168.212V59H175.932Z"
        fill="#0C7BC1"
      />
      <path
        d="M178.559 44.98V59H180.159V53.18H181.259C182.279 53.18 182.899 53.6 183.439 54.52L185.979 59H187.879L185.299 54.62C184.659 53.54 184.159 53.08 183.599 52.82V52.74C185.279 52.56 186.759 51.22 186.759 48.92C186.759 46.12 184.999 44.98 182.079 44.98H178.559ZM180.159 46.4H181.859C183.979 46.4 185.119 47.14 185.119 49.1C185.119 51.04 183.899 51.8 181.959 51.8H180.159V46.4Z"
        fill="#0C7BC1"
      />
    </svg>
  );
};
